.product-quickview-modal-wrapper .modal-dialog {
    margin: 5% 20% !important;
    padding: 0 !important;
}

.sidebar-widget-list-left label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 192px;
}

.product-large-image-wrapper .single-image img {
    width: 100%;
    height: 550px;
    object-fit: contain;
}

.header-right-wrap .same-style .account-dropdown {
    width: 125px !important;
}

.product-small-image-wrapper .ht-swiper-button-nav {
    height: 40px !important;
    width: 40px !important;
}

